

























































import { defineComponent, onMounted } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import ScheduleAlertComponent from '@/components/Management/molecules/ScheduleAlert.vue';
import { useReflectSchedule } from '@/composables/store/Management/Schedule/useReflectSchedule';
import { useScheduleProgressCircular } from '@/composables/store/Management/Schedule/useScheduleProgressCircular';
import { useDate } from '@/composables/function/Common/useDate';
import { useScheduleConfirmDialog } from '@/composables/store/Management/Schedule/useScheduleConfirmDialog';
import { useScheduleTypeLabel } from '@/composables/function/Management/useScheduleTypeLabel';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
        ScheduleAlert: ScheduleAlertComponent,
    },
    setup() {
        const { fetchIndex, scheduleList } = useReflectSchedule();
        const { isScheduleListProgressing } = useScheduleProgressCircular();
        const { openImplementDialog, openCancelDialog } = useScheduleConfirmDialog();
        const { getLabel } = useScheduleTypeLabel();

        onMounted(() => {
            fetchIndex();
        });

        const { dateFormat } = useDate();

        return {
            scheduleList,
            isScheduleListProgressing,
            getLabel,
            dateFormat,
            openImplementDialog,
            openCancelDialog,
        };
    },
});
